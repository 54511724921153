import { useCallback, useEffect, useMemo, useState } from "react"

import { Formik } from "formik"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ArrowRightIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { CTAButton, MenuItem, TagsAutocomplete } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { HORIZONTAL_SIGNING_CONDITIONS, HORIZONTAL_SIGNING_CONDITIONS_VALUES } from "../../constants/horizontalSigningConditions"
import { useHorizontalSigningContext } from "../../containers/HorizontalSignDetailsEditModal/HorizontalSignDetailsEditContext.hooks"
import { useHorizontalSigningProject } from "../../hooks/queries/horizontalSigning/useHorizontalSigningProject"
import { useHorizontalSignTypes } from "../../hooks/queries/horizontalSigning/useHorizontalSignTypes"
import { findHorizontalSignTypeByCode, getFinalHorizontalSignTypes } from "../../hooks/utils/useGetHorizontalSignTypeCatalogUtils"
import * as Styled from "./HorizontalSignDetailsForm.styled"

const CONTENT_MAX_CHARACTERS = 100

export const HorizontalSignDetailsForm = (props) => {

    const {
        initialValues,
        className,
        onCancel,
        onChange,
        onSubmit,
    } = props

    const [parentType, setParentType] = useState(null)
    const [contextState, contextDispatch] = useHorizontalSigningContext()
    const { typeSelectionDisplayed } = contextState
    const { setTypeSelectionDisplayed, setOnBack } = contextDispatch
    const { t } = useTranslation(I18N_NAMESPACE)

    const { data: project } = useHorizontalSigningProject()
    const { data: horizontalSignTypes } = useHorizontalSignTypes()

    const existingTags = useMemo(() => {
        if (project?.tags) {
            return project.tags.sort()
        }

        return []
    }, [project])

    const conditionOptions = useMemo(() => {
        return HORIZONTAL_SIGNING_CONDITIONS.filter(condition => condition > 0)
            .map(condition => <MenuItem
                key={condition}
                value={condition}>
                {t(I18N_NAMESPACE, HORIZONTAL_SIGNING_CONDITIONS_VALUES[condition].label)}
            </MenuItem>)
    }, [t])

    const horizontalSignTypeOptions = useMemo(() => {
        const finalHorizontalTypes = getFinalHorizontalSignTypes(horizontalSignTypes || [])
        return finalHorizontalTypes.map(type => <MenuItem
            key={type.code}
            value={type.code}>
            {type.name}
        </MenuItem>)
    }, [horizontalSignTypes])

    const displayedHorizontalSignTypes = useMemo(() => {
        const types = !parentType ? horizontalSignTypes : parentType.children

        const mapItem = (item => ({
            icon: item.icon,
            label: item.name,
            value: item.code,
            children: item.children.map(child => mapItem(child)),
        }))

        return types?.map(type => mapItem(type))
    }, [parentType, horizontalSignTypes])

    const horizontalSignTypesListTitle = useMemo(() => (
        parentType ? parentType.name : t(I18N_NAMESPACE, "containers.horizontalSignDetailsForm.category")
    ), [parentType, t])

    const compareValues = useCallback((values, initialValues) => {
        const { tags: initialTags, ...initialFields } = initialValues
        const { tags, ...fields } = values

        const areInitialTags = (tags?.length === initialTags.length) && tags.every(tag => initialTags.includes(tag))
        const areInitialFields = Object.keys(fields).every(key => fields[key] === initialFields[key])

        return areInitialTags && areInitialFields
    }, [])

    const handleFieldChange = useCallback((fieldName, value, values) => {
        const newValues = {
            ...values,
            [fieldName]: value,
        }

        const areInitialValues = compareValues(newValues, initialValues)
        onChange(!areInitialValues)
    }, [initialValues, compareValues, onChange])

    const handleOpenTypeSelection = useCallback(() => {
        setTypeSelectionDisplayed(true)
    }, [setTypeSelectionDisplayed])

    const handleTypeHierarchyClick = useCallback((code, setFieldValue, values) => {
        const horizontalSignType = findHorizontalSignTypeByCode(code, horizontalSignTypes)

        if (horizontalSignType?.children?.length) {
            setParentType(horizontalSignType)
        } else {
            setFieldValue("horizontal_sign_type", code)
            handleFieldChange("horizontal_sign_type", code, values)
            setParentType(null)
            setTypeSelectionDisplayed(false)
            if (!horizontalSignType.is_textual) {
                setFieldValue("content", "")
            }
        }
    }, [horizontalSignTypes,
        setParentType,
        setTypeSelectionDisplayed,
        handleFieldChange,
    ])

    const handleTypeHierarchyCancel = useCallback(() => {
        setParentType(null)
        setTypeSelectionDisplayed(false)
    }, [setParentType, setTypeSelectionDisplayed])

    const handleBack = useCallback(() => {
        if (parentType) {
            const parentHorizontalSignType = findHorizontalSignTypeByCode(parentType.parent, horizontalSignTypes)
            setParentType(parentHorizontalSignType)
        } else {
            setTypeSelectionDisplayed(false)
        }
    }, [parentType,
        horizontalSignTypes,
        setParentType,
        setTypeSelectionDisplayed,
    ])

    useEffect(() => {
        setOnBack(handleBack)

        return () => setOnBack(null)
    }, [handleBack, setOnBack])

    return <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
    >
        {({ values, setFieldValue }) => {
            const areInitialTags = compareValues(values, initialValues)

            const horizontalSignType = findHorizontalSignTypeByCode(values.horizontal_sign_type, horizontalSignTypes || [])
            const isTextual = horizontalSignType ? horizontalSignType.is_textual : false

            return !typeSelectionDisplayed ? <Styled.Form className={className}>
                <Styled.Select
                    id="condition-select"
                    name={"condition"}
                    value={values.condition ?? ""}
                    label={t(I18N_NAMESPACE, "containers.horizontalSignDetailsForm.condition")}
                    onChange={v => handleFieldChange("condition", v, values)}>
                    {conditionOptions}
                </Styled.Select>
                <Styled.Select
                    id="type-select"
                    IconComponent={ArrowRightIcon}
                    name={"horizontal_sign_type"}
                    value={values.horizontal_sign_type ?? ""}
                    label={t(I18N_NAMESPACE, "containers.horizontalSignDetailsForm.type")}
                    onOpen={handleOpenTypeSelection}>
                    {horizontalSignTypeOptions}
                </Styled.Select>
                {isTextual && <Styled.TextField
                    id="content-text"
                    name="content"
                    type="text"
                    value={values.content ?? ""}
                    inputProps={{ maxLength: CONTENT_MAX_CHARACTERS }}
                    label={t(I18N_NAMESPACE, "containers.horizontalSignDetailsForm.panelText")}
                    labelOutside={false}
                    onChange={e => handleFieldChange("content", e.target.value, values)} />}
                <TagsAutocomplete 
                    name="tags"
                    variant="outlined"
                    tags={values.tags} 
                    existingTags={existingTags} 
                    onChange={v => handleFieldChange("tags", v, values)} 
                />
                <Styled.ButtonsWrapper>
                    <CTAButton
                        id="cancel-button"
                        onClick={() => {
                            onCancel()
                        }}
                        variant="outlined"
                    >
                        {t(I18N_NAMESPACE, "containers.horizontalSignDetailsForm.cancel")}
                    </CTAButton>
                    <Styled.SubmitButton
                        id="submit-button"
                        color="cta-bg/cta-bg-primary"
                        type="submit"
                        variant="contained"
                        disabled={areInitialTags}
                    >
                        {t(I18N_NAMESPACE, "containers.horizontalSignDetailsForm.save")}
                    </Styled.SubmitButton>
                </Styled.ButtonsWrapper>
            </Styled.Form>
                : <Styled.HierarchicalList
                    data={displayedHorizontalSignTypes}
                    title={horizontalSignTypesListTitle}
                    onCancel={handleTypeHierarchyCancel}
                    onClick={(code) => handleTypeHierarchyClick(code, setFieldValue, values)} />
        }}

    </Formik>
}

HorizontalSignDetailsForm.propTypes = {
    signTags: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}