import {
    getLineLayerConfig,
    getInterpolatedLineLayerWidth,
    getInterpolatedSelectableLineLayerWidth,
    LINE_LAYER_SIZE,
    SELECTED_LINE_LAYER_SIZE,
} from "@l2r-front/l2r-map"
import { selectLayerColorOpacity } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../common/constants/i18n"
import { impacts } from "./impacts"

export const ROADWORK_LAYER_WIDTH = LINE_LAYER_SIZE + 2

export const getRoadworkLegendConfig = (t, theme) => {
    return Object.values(impacts).map(legendValue => {
        return {
            color: theme.palette[legendValue.backgroundColor]?.main,
            label: t(I18N_NAMESPACE, legendValue.text),
        }
    })
}

const getRoadworksBaseConfig = (referentialColor, selectedRoad = null, theme) => {
    const legendStyles = Object.entries(impacts).map(([impactValue, impactConfig]) => {
        const color = theme.palette[impactConfig.backgroundColor].main
        return [
            ["==", ["get", "impact", ["get", "technique", ["properties"]]], parseInt(impactValue)],
            selectLayerColorOpacity(color, 0.5, selectedRoad),
        ]
    })

    const impactsValuesArray = Object.values(impacts)
    const lastColor = theme.palette[impactsValuesArray[impactsValuesArray.length - 1].backgroundColor].main
    legendStyles.push(lastColor)

    return getLineLayerConfig({
        "paint": {
            "line-color": [
                "case",
                ["==", ["get", "technique", ["properties"]], null],
                theme.palette["colors/vert logiroad/400"].main,
                ["!=", ["typeof", ["get", "impact", ["get", "technique", ["properties"]]]], "number"],
                selectLayerColorOpacity(referentialColor, referentialColor === "#FFFFFF" ? 0.85 : 0.5, selectedRoad),
            ].concat(legendStyles.flat()),
            "line-width": getInterpolatedSelectableLineLayerWidth(selectedRoad),
            "line-dasharray": [
                "case",
                ["==", ["get", "technique", ["properties"]], null],
                ["literal", [1]],
                ["==", ["get", "impact", ["get", "technique", ["properties"]]], 10],
                ["literal", [1, 1]],
                ["literal", [1, 0]],
            ],
        },
        "layout": {
            "line-sort-key": ["get", "zIndex"],
        },
    })
}

export const getRoadworkTechniquesLayerStyle = (referentialColor, selectedTechnique = null, selectedRoad = null, filters = {}, theme) => {
    const roadworksBaseConfig = getRoadworksBaseConfig(referentialColor, selectedRoad, theme)

    const filteredYears = filters.years
    const filteredTechniques = filters.techniques
    const filteredStatuses = filters.statuses

    const [selectedTechniqueCode, selectedTechniqueYear, selectedTechniqueStatus] = selectedTechnique?.split("-") ?? [null]
    const yearFilter = selectedTechniqueYear ? [parseInt(selectedTechniqueYear)] : filteredYears
    const statusFilter = selectedTechniqueStatus ? [parseInt(selectedTechniqueStatus)] : filteredStatuses
    const techniqueFilter = selectedTechniqueCode ? [selectedTechniqueCode] : filteredTechniques

    return {
        ...roadworksBaseConfig,
        "filter": [
            "all",
            !selectedRoad && ["!=", ["get", "technique", ["properties"]], null],
            yearFilter?.length && ["in", ["get", "year"], ["literal", yearFilter]],
            statusFilter?.length && ["in", ["get", "status"], ["literal", statusFilter]],
            techniqueFilter?.length && ["in", ["get", "code", ["get", "technique", ["properties"]]], ["literal", techniqueFilter]],
        ].filter(Boolean),
    }
}

export const getRoadworksLayerStyle = (referentialColor, selectedRoadwork, selectedRoad = null, filters = {}, theme) => {
    const roadworksBaseConfig = getRoadworksBaseConfig(referentialColor, selectedRoad, theme)
    const filteredYears = filters.years
    const filteredTechniques = filters.techniques

    return {
        ...roadworksBaseConfig,
        "filter": [
            "all",
            ["!=", ["get", "technique", ["properties"]], null],
            filteredYears?.length && ["in", ["get", "year"], ["literal", filteredYears]],
            filteredTechniques?.length && ["in", ["get", "code", ["get", "technique", ["properties"]]], ["literal", filteredTechniques]],
            selectedRoadwork && ["==", ["get", "uuid", ["properties"]], selectedRoadwork],
        ].filter(Boolean),
    }
}

export const getLineLayerStyled = (color) => {

    return getLineLayerConfig({
        "paint": {
            "line-color": `${color}`,
            "line-width": getInterpolatedLineLayerWidth(SELECTED_LINE_LAYER_SIZE),
        },
    })
}