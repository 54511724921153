import { useCallback } from "react"

import {useTranslation} from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useHorizontalSignUpdate } from "../../hooks/mutations/useHorizontalSignUpdate"
import { useHorizontalSign } from "../../hooks/queries/horizontalSigning/useHorizontalSigns"
import { HorizontalSignDetailsForm } from "./HorizontalSignDetailsForm"
import * as Styled from "./HorizontalSignDetailsForm.styled"

export const HorizontalSignDetailsFormPost = (props) => {

    const {
        className,
        close,
        signId,
        ...otherProps
    } = props

    const {t} = useTranslation(I18N_NAMESPACE)
    const navigate = useNavigateWithSearchParams()
    
    const { data: horizontalSign, isLoading, isRefetching, isError } = useHorizontalSign(signId)
    
    const mutation = useHorizontalSignUpdate(signId, {}, {
        onSuccess: (data) => navigate(`../${data.linear_location.road}/${data.uuid}`),
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.horizontalSignDetailsForm.snackbar.error"),
        successSnackbarMessage: t(I18N_NAMESPACE, "containers.horizontalSignDetailsForm.snackbar.success"),
    })

    const onSubmit = useCallback((values) => {
        mutation.mutate(values)
        close(false)
    }, [close, mutation])


    if (isLoading || isRefetching) {
        return <Styled.FormSkeleton className={className} />
    }

    if (isError) {
        return <Styled.ErrorMessage>{t(I18N_NAMESPACE, "containers.horizontalSignDetailsForm.error")}</Styled.ErrorMessage>
    }

    return <HorizontalSignDetailsForm
        className={className}
        {...otherProps}
        initialValues={horizontalSign}
        onSubmit={onSubmit}
        onCancel={close}
    />
}

HorizontalSignDetailsFormPost.propTypes = {
    className: PropTypes.string,
    close: PropTypes.func.isRequired,
    signId: PropTypes.string.isRequired,
}