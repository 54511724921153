import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Modal, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { HorizontalSignLocationEditFormPost } from "../../forms/HorizontalSignLocationEditForm/HorizontalSignLocationEditForm.post"
import * as Styled from "./HorizontalSignLocationEditModal.styled"

export const HorizontalSignLocationEditModal = (props) => {

    const {
        className,
        signId,
        startingPosition,
        startingRoad,
        open,
        onClose,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const isMobile = useIsMobileDevice()

    return <Modal
        id="horizontalSign-edit-modal"
        className={className}
        open={open}
        onClose={onClose}
    >
        <Styled.Box isMobile={isMobile}>
            <Styled.Title variant="H1">{t(I18N_NAMESPACE, "containers.horizontalSignLocationEditForm.title")}</Styled.Title>
            <Styled.Desc variant="H3">{t(I18N_NAMESPACE, "containers.horizontalSignLocationEditForm.desc")}</Styled.Desc>
            <Styled.IconButton id="close-button" onClick={onClose}>
                <Styled.CloseIcon />
            </Styled.IconButton>
            <HorizontalSignLocationEditFormPost 
                close={onClose}
                signId={signId}
                startingPosition={startingPosition}
                startingRoad={startingRoad}/>
        </Styled.Box>
    </Modal>
}

HorizontalSignLocationEditModal.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    signId: PropTypes.string.isRequired,
    startingPosition: PropTypes.shape({
        longitude: PropTypes.number,
        latitude: PropTypes.number,
    }).isRequired,
    startingRoad: PropTypes.string,
}
